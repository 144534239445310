import React from 'react'
import './experience.css'
import {FaCheckCircle} from 'react-icons/fa'

const Experience = () => {
  return (
    <section id = 'experience'>
      <h2>Skills I have</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>HTML</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>XML</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>React JS</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>CSS</h4>
              <small className='text-light'>Experienced</small>
            </article>
            
          </div>
          </div>

          <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Django</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>REST API</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>JSON</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>DOCKER</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>FLASK</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Nginx</h4>
              <small className='text-light'>Intermediate</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Google Firebase</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Node JS</h4>
              <small className='text-light'>Intermediate</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>MySQL</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>MongoDB</h4>
              <small className='text-light'>Intermediate</small>
            </article>
            </div>
            </div>
          

          <div className="experience__testing">
          <h3>Testing</h3>
          <div className="experience__content">
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>JUnit</h4>
              <small className='text-light'>Intermediate</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>JEST</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>PyTest</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Selenium</h4>
              <small className='text-light'>Intermediate</small>
            </article>

            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Enzyme</h4>
              <small className='text-light'>Intermediate</small>
            </article>

            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Robotium</h4>
              <small className='text-light'>Intermediate</small>
            </article>

            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Espresso</h4>
              <small className='text-light'>Intermediate</small>
            </article>

            
          </div>
          </div>


          <div className="experience__programming">
          <h3>Programming Language</h3>
          <div className="experience__content">
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>C</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>C++</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>C#</h4>
              <small className='text-light'>Intermediate</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Python</h4>
              <small className='text-light'>Experienced</small>
            </article>
            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>JavaScript</h4>
              <small className='text-light'>Experienced</small>
            </article>

            <article  className='experience_details'>
              <FaCheckCircle/>
              <h4>Java</h4>
              <small className='text-light'>Intermediate</small>
            </article>
            </div>
          </div>
            
          </div>

      </section> 
  )
}

export default Experience

