import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {BiUserCircle} from 'react-icons/bi'
import {BsBook} from 'react-icons/bs'
import {AiOutlineProject} from 'react-icons/ai'
import {BiMessageDetail} from 'react-icons/bi'
import {useState} from 'react'



const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
    return (
    <nav>
    <a href="#" onClick = {() => setActiveNav('#')} className= {activeNav === '#' ? 'active' : '' }><AiOutlineHome/></a>
    <a href="#about" onClick = {() => setActiveNav('about')} className= {activeNav === 'about' ? 'active' : '' }><BiUserCircle/></a>
    <a href="#experience" onClick = {() => setActiveNav('experience')} className= {activeNav === 'experience' ? 'active' : '' }><BsBook/></a>
    <a href="#portfolio" onClick = {() => setActiveNav('portfolio')} className= {activeNav === 'portfolio' ? 'active' : '' }><AiOutlineProject/></a>
    <a href="#contact" onClick = {() => setActiveNav('contact')} className= {activeNav === 'contact' ? 'active' : '' }><BiMessageDetail/></a>
    </nav>
  )
}

export default Nav