import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {BsFacebook} from 'react-icons/bs'
import {GoMarkGithub} from 'react-icons/go'
import {GrInstagram} from 'react-icons/gr'

const Footer = () => {
  return (
    <footer> 
      <a href = "#" className='footer__logo'>MAINUL</a>

      <ul className='permalinks'>
      <li><a href="#">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#experience">Experience</a></li>
      <li><a href="#portfolio">Portfolio</a></li>
      <li><a href="#contact">Contact</a></li>
      </ul>
     <div className="footer__socials">
     <a href = "https://www.linkedin.com/in/mainul47/" target="_blank"><BsLinkedin/></a>
        <a href = "https://github.com/Mainul47" target="_blank"><GoMarkGithub/></a>
        <a href = "https://www.facebook.com/mainul.islam.1654" target="_blank"><BsFacebook/></a>
        <a href = "https://www.instagram.com/mainul.islam/?hl=en" target="_blank"><GrInstagram/></a>
      </div>  
      <div className="footer__copyright">
        <small>&copy; MAINUL ISLAM. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer