import React from 'react'
import './about.css'
import ME from '../../assets/b1.jpg'
import {BsFillAwardFill} from 'react-icons/bs'
import {FaUniversity} from 'react-icons/fa'
import {AiOutlineFundProjectionScreen} from 'react-icons/ai'


const About = () => {
  return (
    <section id = 'about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div cladssName="about__me">
          <div className="about__me-image">
            <img src= {ME} alt = "About Image" />
          </div>
        </div>

        <div className="about__container">
          <div className="about__cards">
            <article className='about__card'> 
            <BsFillAwardFill className='about__icon'/>
            <h5>Experience</h5>
            <small>Nearly 5+ years of programming Experience with several promgramming languages</small>
            </article>

            <article className='about__card'> 
            <FaUniversity className='about__icon'/>
            <h5>Education</h5>
            <small>4th Year Computer Science and Mathematics Student from University of Alberta</small>
            </article>

            <article className='about__card'> 
            <AiOutlineFundProjectionScreen className='about__icon'/>
            <h5>Projects</h5>
            <small>Worked on several projects including non-profit open source projects</small>
            </article>
        </div>


        </div>
        
      </div>
      
    </section> 
  )
}

export default About