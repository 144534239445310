import React from 'react'
import './header.css' 
import CTA from './CTA'
import ME from '../../assets/t1.jpg'
import HeaderSocial from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header_container">
        <h5>Hello I am</h5>
        <h1>Mainul Islam</h1>
        <h5 className="textlight">Fullstack Application and Web Developer</h5>
        <h5 className="textlight">RPA and Software Development Intern at AHS</h5>
        <CTA/>
        <HeaderSocial/>


        <div className="me">
          <img src={ME} alt="me" />
        </div>

        <a href="#contact" className='scroll_down'>Scroll Down</a>
      </div>
    </header>
    )
}

export default Header