import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsFacebook} from 'react-icons/bs'
import {GoMarkGithub} from 'react-icons/go'
import {GrInstagram} from 'react-icons/gr'


const HeaderScroll = () => {
  return (
    <div className='header_socials'>
        <a href = "https://www.linkedin.com/in/mainul47/" target="_blank"><BsLinkedin/></a>
        <a href = "https://github.com/Mainul47" target="_blank"><GoMarkGithub/></a>
        <a href = "https://www.facebook.com/mainul.islam.1654" target="_blank"><BsFacebook/></a>
        <a href = "https://www.instagram.com/mainul.islam/?hl=en" target="_blank"><GrInstagram/></a>
    </div>
    
    
  )
}

export default HeaderScroll