import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/g1.png'
import IMG2 from '../../assets/p1.png'
import IMG3 from '../../assets/s1.png'
import IMG4 from '../../assets/h1.png'

const Portfolio = () => {
  return (
    <section id = 'portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">

      <article className= 'portfolio__item'>
          <div className="portfolio__item-image">
            <img src = {IMG2} alt=""/>
          </div>

            <h3>YEG Police Violance Portal</h3>
            <div className="portfolio__item-cta">
            <a href="https://cmput401.ca/projects/29fcb515-cee5-42ee-b708-d73dfe359023" className='btn' target= '_blank'>Live Demo</a>
            </div>
            
            

        </article>

        <article className= 'portfolio__item'>
          <div className="portfolio__item-image">
            <img src = {IMG3} alt=""/>
          </div>

            <h3>StudyBuddy</h3>
            <div className="portfolio__item-cta">
            <a href="https://github.com/grapse/studybuddy" className='btn' target= '_blank'>Github Repository</a>
            </div>
           
            

        </article>
        

        <article className= 'portfolio__item'>
          <div className="portfolio__item-image">
            <img src = {IMG1} alt=""/>
          </div>

            <h3>Connect 4 Game using Alpha Beta Prunning</h3>
            <div className="portfolio__item-cta">
            <a href="https://github.com/Mainul47/Connect_4_board_game_with_minimax_and_alpha_beta_pruning" className='btn' target= '_blank'>Github Repository</a>
            </div>
            
            

        </article>

        



        <article className= 'portfolio__item'>
          <div className="portfolio__item-image">
            <img src = {IMG4} alt=""/>
          </div>

            <h3>The Habit Controller Android App</h3>
            <div className="portfolio__item-cta">
            <a href="https://github.com/CMPUT301F21T06/TheHabitController" className='btn' target= '_blank'>Github Repository</a>
            </div>
            
            

        </article>


      </div>

      </section> 
  )
}

export default Portfolio