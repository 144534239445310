import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qr5hu0n', 'template_2u4b88e', form.current, 'LhEyq0bQIVQyJQM2U')
    e.target.reset()


     
  };


  return (
    <section id = 'contact'>
      <h5> Get In touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>mainul1@ualberta.ca</h5>
            <a href="mailto:mainul1@ualberta.ca" target="_blank">Send a message</a>
          </article>

          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Mainul Islam</h5>
            <a href="https://m.me/mainul.islam.1654" target="_blank">Send a message</a>
          </article>

          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>Contact me</h5>
            <a href="https://api.whatsapp.com/send?phone=+13068075162" target="_blank">Send a message</a>
          </article>



        </div>
        <form ref={form} onSubmit={sendEmail} >
          <input type="text" name='name' placeholder='Your Full Name'required />
          <input type="email" name='email' placeholder='Your Email'required />
          <textarea type="message" rows="7" placeholder='Your Message'required ></textarea>
          <button type = 'submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>

    </section> 
  )
}

export default Contact